<template>
  <svg
    width="25"
    height="25"
    fill="none"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1.5"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2Z"
    ></path>
    <path d="M9.5 21V3"></path>
    <path d="m5.5 10 1.75 2-1.75 2"></path>
  </svg>
</template>
