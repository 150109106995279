<template>
	<svg
		class="mr-2 dark:dark-analytics-svg"
		width="25"
		height="25"
		fill="none"
		stroke="currentColor"
		stroke-linecap="round"
		stroke-linejoin="round"
		stroke-width="1.5"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m4.031 8.915 15.477-4.333a.5.5 0 0 1 .616.616l-4.333 15.476a.5.5 0 0 1-.94.067l-3.248-7.382a.5.5 0 0 0-.256-.257L3.965 9.854a.5.5 0 0 1 .066-.939Z"
		></path>
	</svg>
</template>