<template>
  <svg
    class="w-3.5 h-3.5 text-primary"
    fill="none"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1.6"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.528 1.596a.6.6 0 0 1 .944 0l1.809 2.3a.6.6 0 0 0 .635.207l2.815-.798a.6.6 0 0 1 .764.555l.11 2.924a.6.6 0 0 0 .393.54l2.747 1.01a.6.6 0 0 1 .292.897l-1.63 2.431a.6.6 0 0 0 0 .668l1.63 2.432a.6.6 0 0 1-.292.897l-2.747 1.01a.6.6 0 0 0-.392.54l-.111 2.924a.6.6 0 0 1-.764.555l-2.815-.798a.6.6 0 0 0-.636.206l-1.808 2.3a.6.6 0 0 1-.944 0l-1.809-2.3a.6.6 0 0 0-.635-.206l-2.815.797a.6.6 0 0 1-.764-.554l-.11-2.924a.6.6 0 0 0-.393-.54l-2.747-1.01a.6.6 0 0 1-.292-.897l1.63-2.432a.6.6 0 0 0 0-.668l-1.63-2.43a.6.6 0 0 1 .292-.898l2.747-1.01a.6.6 0 0 0 .392-.54l.111-2.924a.6.6 0 0 1 .764-.555l2.815.798a.6.6 0 0 0 .635-.206l1.81-2.3Z"
    ></path>
    <path d="m9 12 2 2 4-4"></path>
  </svg>
</template>
