<script setup></script>
<template>
  <svg
    width="25"
    height="25"
    class="#9ca3af w-6 h-6"
    fill="none"
    stroke="#9ca3af"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1.5"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3 10v9a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-9H3Z"></path>
    <path d="M3 10V6a2 2 0 0 1 2-2h2"></path>
    <path d="M7 2v4"></path>
    <path d="M21 10V6a2 2 0 0 0-2-2h-.5"></path>
    <path d="M15 2v4"></path>
    <path d="M15 4h-4.5"></path>
  </svg>
</template>

<style></style>
