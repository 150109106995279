<template>
	<svg
		class="mr-2 dark:dark-analytics-svg"
		width="25"
		height="25"
		fill="none"
		stroke="currentColor"
		stroke-linecap="round"
		stroke-linejoin="round"
		stroke-width="1.5"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M15 5 9 3m0 16-5.21 1.737a.6.6 0 0 1-.79-.57V5.433a.6.6 0 0 1 .41-.569L9 3v16Zm0 0 6 2-6-2Zm0 0V3v16Zm6 2 5.59-1.863a.6.6 0 0 0 .41-.57V3.832a.6.6 0 0 0-.79-.569L15 5v16Zm0 0V5v16Z"
		></path>
	</svg>
</template>