<script setup>
const notificationOverlay = ref();
const toggleNotification = (event) => {
  notificationOverlay.value.toggle(event);
};
</script>

<template>
  <Button
    aria-label="userNotification"
    @click="toggleNotification"
    icon="pi pi-bell text-gray-500"
    class="relative !p-3 hover:!bg-gray-100 dark:!hover:bg-gray-700 focus:ring-0 focus:outline-none !mr-3"
  />

  <OverlayPanel
    class="!shadow-5xl border rounded-xl"
    ref="notificationOverlay"
    id="notification-overlay-panel"
  >
    <div class="w-80">
      <h1 class="font-bold mb-4">Notifications</h1>
      <hr class="mb-5 mt-4 dark:border-slate-50" />
      <div class="w-full text-center">
        <span class="text-center text-sm font-medium">No new notifications</span>
      </div>
    </div>
  </OverlayPanel>
</template>

<style lang="postcss">
#notification-overlay-panel > .p-overlaypanel-content {
  @apply rounded-xl shadow-lg;
}
</style>
