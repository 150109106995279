<script setup>
import { useDateFormat } from "@vueuse/core";

const { currentTime } = useCurrentTime();
const formattedDate = useDateFormat(currentTime, "dddd, D MMMM");
</script>

<template>
  <div class="mx-5 border-r border-l px-5 hidden lg:grid md:hidden select-none">
    <span class="font-bold text-lg dark:text-gray-400">{{
      currentTime.toLocaleTimeString()
    }}</span>
    <span class="text-sm text-gray-500">
      {{ formattedDate }}
    </span>
  </div>
</template>

<style></style>
