<template>
	<svg
		class="mr-2 dark:dark-analytics-svg"
		width="25"
		height="25"
		fill="none"
		stroke="currentColor"
		stroke-linecap="round"
		stroke-linejoin="round"
		stroke-width="1.5"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M2 21h15"></path>
		<path d="M21 21h1"></path>
		<path
			d="M2 16.4V3.6a.6.6 0 0 1 .6-.6h18.8a.6.6 0 0 1 .6.6v12.8a.6.6 0 0 1-.6.6H2.6a.6.6 0 0 1-.6-.6Z"
		></path>
	</svg>
</template>