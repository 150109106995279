<script setup>
import { storeToRefs } from "pinia";
import { useLeftPanelStore } from "~/store/useLeftPanelStore.js";

const { isLeftPanelExpanded, showLeftPanelAlert } = storeToRefs(useLeftPanelStore());
</script>
<template>
  <div
    class="flex flex-col items-center max-h-screen min-h-full overflow-hidden h-full text-gray-400 bg-[#f1f4fa] dark:bg-[#181818]"
    :class="isLeftPanelExpanded ? 'w-64' : 'w-20'"
  >
    <div
      class="flex flex-col items-center"
      :class="{
        'w-full h-[70%]': isLeftPanelExpanded,
        'w-full h-[90%]': !isLeftPanelExpanded,
        'h-[90%]': !showLeftPanelAlert,
      }"
    >
      <LeftPanelHeader />

      <LeftPanelMenu />
    </div>
    <DashboardUpgradeToPremium />
    <LeftPanelExpandCollapseBtn />
  </div>
</template>

<style lang="postcss"></style>
