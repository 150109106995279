<template>
	<svg
		class="w-6 h-6"
		width="25"
		height="25"
		fill="none"
		stroke="#9ca3af"
		stroke-linecap="round"
		stroke-linejoin="round"
		stroke-width="1.5"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M8 6h12"></path>
		<path d="M4 6.011 4.01 6"></path>
		<path d="M4 12.011 4.01 12"></path>
		<path d="M4 18.011 4.01 18"></path>
		<path d="M8 12h12"></path>
		<path d="M8 18h12"></path>
	</svg>
</template>