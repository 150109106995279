<script setup>
import { CALENDLY_15_MIN_CALL_URL } from "~/utils/constants";

const calendly = useCalendly();
const handleClick = () => {
  calendly.showPopupWidget(CALENDLY_15_MIN_CALL_URL);
};
</script>

<template>
  <Button @click="handleClick" text icon="pi pi-phone" label="Book a call" />
</template>

<style></style>
