<script setup>
import { storeToRefs } from "pinia";
import { useLeftPanelStore } from "~/store/useLeftPanelStore.js";

const { t } = useI18n();
const { isLeftPanelExpanded } = storeToRefs(useLeftPanelStore());
</script>
<template>
  <div
    class="mb-5 mt-[2.4rem] w-full"
    :class="[
      { 'pl-[2.3rem]': isLeftPanelExpanded },
      { 'flex justify-center': !isLeftPanelExpanded },
    ]"
  >
    <NuxtLink to="/">
      <div type="button" class="flex items-center select-none">
        <NuxtImg
          provider="awsS3"
          src="/logo.png"
          class="md:h-8 sm:h-6 pointer-events-none"
          alt="Smartlnks.com"
          :class="{ 'mr-2': isLeftPanelExpanded }"
          data-not-lazy
        />
        <span
          class="md:text-2xl text-xl text-black cursor-pointer font-semibold"
          :class="isLeftPanelExpanded ? 'block' : 'hidden'"
          >{{ t("smartlnks") }}</span
        >
      </div>
    </NuxtLink>
  </div>
</template>

<style></style>
