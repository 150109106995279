<script setup>
import { storeToRefs } from "pinia";
import { useLeftPanelStore } from "~/store/useLeftPanelStore.js";

const useLeftPanel = useLeftPanelStore();
const { isLeftPanelExpanded, showLeftPanelAlert } = storeToRefs(useLeftPanel);
const { closeLeftPanelAlert } = useLeftPanel;
</script>

<template>
  <div class="justify-center mt-auto w-full select-none">
    <div
      v-if="isLeftPanelExpanded && showLeftPanelAlert"
      class="bg-cover bg-[url('https://smartlnks-assets.s3.ap-south-1.amazonaws.com/space.webp')] p-4 rounded-lg m-2 text-left"
      role="alert"
      data-not-lazy
    >
      <div class="flex items-center mb-3">
        <!-- <span
            class="bg-orange-100 text-orange-800 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-orange-200 dark:text-orange-900"
            >Beta</span
        > -->
        <button
          type="button"
          class="ml-auto -mx-1.5 -my-1.5 rounded-lg p-1 inline-flex h-6 w-6 bg-white"
          data-collapse-toggle="dropdown-cta"
          aria-label="Close"
          @click="closeLeftPanelAlert()"
        >
          <span class="sr-only">Close</span>
          <svg
            aria-hidden="true"
            class="w-4 h-4"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
      <p class="mb-7 text-2xl text-white font-bold">
        Upgrade to <br />
        premium
      </p>
      <a class="bg-white py-2 px-5 rounded-xl text-black font-medium">Launching Soon </a>
      <div class="my-2"></div>
    </div>
  </div>
</template>

<style></style>
