<script setup>
import { storeToRefs } from "pinia";
import { useDashboardNavStore } from "~/store/dashboardNav.js";
import { Vue3Lottie } from 'vue3-lottie'

const { header } = storeToRefs(useDashboardNavStore());

function reloadIt() {
  window.location.reload();
}
</script>

<template>
  <!-- class: px-2 sm:px-4 py-3 -->
  <!-- removed when added ramadan kareen mb-7 -->
  <nav class="w-full z-20 top-0 left-0 mb-7">
    <div class="container-fluid flex flex-wrap md:justify-between justify-end items-center mx-auto">
      <div class="flex md:order-2 items-center" id="right-side-btns">
        <DashboardNavSearch />

        <!-- RealTime -->
        <DashboardRealTimeOverlayBtn />

        <!-- digital clock -->
        <DashboardDigitalClock />

        <!-- Notification -->
        <DashboardNotificationBell />
        <!-- Notification end -->

        <!-- Dark mode switcher -->
        <!-- <DashboardDarkModeSwitch /> -->
        <!-- Dark mode switcher end -->

        <!-- User Profile -->
        <DashboardUserProfile />
        <!-- User Profile end -->

        <button data-collapse-toggle="navbar-sticky" type="button"
          class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 z-10"
          aria-controls="navbar-sticky" aria-expanded="false" @click="reloadIt()">
          <span class="sr-only">Open main menu</span>
          <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"></path>
          </svg>
        </button>
      </div>

      <!-- Ramadan kareem -->
      <!-- <div class="justify-start hidden items-start w-full lg:flex lg:w-auto lg:order-1" id="navbar-sticky">
        <div class="items-center text-left">
          <client-only>
            <Vue3Lottie animationLink="https://lottie.host/5287d9d9-e666-4812-9110-13422e46d20d/VBjkjZJq2d.json"
              :height="80" />
          </client-only>
        </div>
      </div> -->

      <!-- middle -->
      <div>
        <h1 class="font-bold text-xl dark:text-slate-50 select-none">
          {{ header }}
        </h1>
      </div>
    </div>
  </nav>
</template>

<style lang="postcss" scoped>
.p-input-icon-right {
  @apply hidden md:inline-block lg:inline-block;
}
/* !hidden md:inline-block lg:inline-block */
</style>
<style>
#right-side-btns > .pi {
  display: none;
}
.nav-search {
  background: rgb(243 244 246);
}

@media (min-width: 640px) {
  #right-side-btns > .pi {
    display: inline-block;
  }
}
@media (min-width: 768px) {
  #right-side-btns > .pi {
    display: inline-block;
  }
}
@media (min-width: 1024px) {
  #right-side-btns > .pi {
    display: inline-block;
  }
}
</style>
