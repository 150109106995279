<script setup>
import { useAuthStore } from "~/store/auth.js";
import { storeToRefs } from "pinia";
import { createAvatar } from "@dicebear/core";
import { micah } from "@dicebear/collection";
import { useAccountApi } from "~/composables/useAccountApi.js";

const { header, userInfo } = storeToRefs(useAuthStore());
const { signOutUserApi, loading } = useAccountApi();

const userProfileOverlay = ref();
const toggleUserProfile = (event) => {
  userProfileOverlay.value.toggle(event);
};

const avatar = ref(
  createAvatar(micah, {
    seed: userInfo?.value?.user?.first_name,
    size: 45,
  }).toDataUriSync()
);
</script>

<template>
  <div>
    <Button
      aria-label="userProfile"
      @click="toggleUserProfile"
      icon="pi pi-user text-gray-500"
      class="relative !p-3 hover:!bg-gray-100 dark:!hover:bg-gray-700 focus:ring-0 focus:outline-none"
    />

    <OverlayPanel
      id="userProfile-overlay-panel"
      ref="userProfileOverlay"
      class="!shadow-5xl border rounded-xl z-10"
    >
      <div class="w-64">
        <div class="p-1">
          <div class="flex justify-between items-center mb-3">
            <Avatar
              v-if="userInfo?.profile_picture"
              :image="userInfo?.profile_picture"
              size="large"
              data-not-lazy
            />
            <img
              class="select-none pointer-events-none"
              v-else
              :src="avatar"
              alt="Avatar"
              data-not-lazy
            />

            <button
              @click="signOutUserApi()"
              :loading="loading"
              type="button"
              class="text-white select-none bg-primary hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-xs px-3 py-1.5 dark:bg-blue-600 dark:hover:bg-primary focus:outline-none dark:focus:ring-blue-800"
            >
              Sign Out
            </button>
          </div>

          <p class="text-base font-semibold leading-none text-gray-900 dark:text-white">
            <span v-if="userInfo?.first_name" class="flex">
              <span class="pt-1.5 cursor-default"
                >{{ userInfo?.first_name }} {{ userInfo?.last_name }}</span
              >
              <SvgVerified v-if="userInfo?.is_verified" />
            </span>
          </p>

          <p class="mb-3 text-sm font-normal">
            <span v-if="userInfo?.email" class="hover:underline cursor-pointer">{{
              userInfo?.email
            }}</span>
            <Skeleton v-else width="10rem" class="mb-2"></Skeleton>
          </p>
          <hr class="dark:border-slate-50" />

          <div class="my-2 flex flex-col gap-3 user-profile-overlay-quick-btns">
            <BookACall />
          </div>

          <hr class="mb-3 dark:border-slate-50" />

          <div>
            <p class="font-semibold text-xs">
              <span class="block text-sm"> Chat to us </span>
              <span class="text-xs font-normal text-slate-600"
                >Our friendly team is here to help.</span
              >
            </p>

            <p class="font-semibold mt-1">team@smartlnks.com</p>
          </div>
        </div>
      </div>
    </OverlayPanel>
  </div>
</template>

<style lang="postcss">
#userProfile-overlay-panel > .p-overlaypanel-content {
  @apply rounded-xl shadow-2xl;
}

.user-profile-overlay-quick-btns button {
  @apply !bg-inherit !border-transparent  !text-black font-semibold w-full !px-0;
}

.user-profile-overlay-quick-btns button > .p-button-label {
  @apply text-left;
}
</style>
