<script setup>
import { storeToRefs } from "pinia";
import { useLeftPanelStore } from "~/store/useLeftPanelStore.js";

const leftPanelStore = useLeftPanelStore();
const { isLeftPanelExpanded } = storeToRefs(leftPanelStore);
const { toggleLeftPanel } = leftPanelStore;
</script>
<template>
  <div
    @click="toggleLeftPanel"
    type="button"
    class="flex items-center justify-center p-4 w-full cursor-pointer"
  >
    <div v-if="isLeftPanelExpanded" class="flex">
      <SvgSidebarCollapse /> <span class="ml-2 font-medium select-none">Collapse</span>
    </div>
    <div v-else><SvgSidebarExpand /></div>
  </div>
</template>

<style></style>
