<script setup>
import { useLeftPanel } from "~/composables/useLeftPanel.js";
import { useLeftPanelStore } from "~/store/useLeftPanelStore.js";
import { storeToRefs } from "pinia";

const { menuItems } = useLeftPanel();
const { isLeftPanelExpanded, activeTab, activeSubMenuTab } = storeToRefs(
  useLeftPanelStore()
);

const itemClasses = (item) => {
  const baseClasses =
    "flex items-center justify-center !border-none w-12 h-12 !p-0 !mt-5 cursor-pointer bg-[#f1f4fa] dark:bg-[#181818] text-gray-500 hover:bg-black hover:text-white";

  const isActive = activeTab.value === item.key;
  const isSubMenuActive = activeSubMenuTab.value === item.key;
  const isExpanded = isLeftPanelExpanded.value;

  const dynamicClasses = {
    "!bg-black !text-white tabActive": isActive || (isSubMenuActive && !isExpanded),
    "!py-7 !bg-black !text-white tabActiveExpanded":
      isActive || (isSubMenuActive && isExpanded),
    "!w-full px-10 !rounded-xl !justify-start !pl-6 ": isExpanded,
    "!rounded-xl shadow !py-0": !isExpanded,
  };

  return [
    baseClasses,
    ...Object.entries(dynamicClasses)
      .filter(([_, condition]) => condition)
      .map(([className]) => className),
    item.class,
  ];
};
</script>
<template>
  <PanelMenu
    id="dashboard-LeftPanel"
    :model="menuItems"
    :class="{ 'w-full overflow-y-auto': isLeftPanelExpanded }"
    class="mt-4 px-3 remove-scroll"
  >
    <template #item="{ item }">
      <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
        <a
          v-ripple
          :href="href"
          @click="navigate"
          v-tooltip="{
            value: item.label,
            disabled: isLeftPanelExpanded ? true : false,
            class: 'small-tooltip',
          }"
          class="menu-item"
          :class="itemClasses(item)"
        >
          <component :is="item.icon" />
          <span v-if="isLeftPanelExpanded" class="ml-2 text-color">{{ item.label }}</span>
        </a>
      </router-link>
      <a
        v-else
        v-ripple
        :href="item.url"
        :target="item.target"
        class="menu-item"
        :class="itemClasses(item)"
      >
        <component :is="item.icon" />
        <span v-if="isLeftPanelExpanded" class="ml-2 text-color">{{ item.label }}</span>
        <span
          v-if="item.items && isLeftPanelExpanded"
          class="text-white ml-auto mr-4 pi pi-angle-down"
        />
      </a>
    </template>
  </PanelMenu>
</template>

<style lang="postcss">
.tabActive > svg > path {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7493%)
    hue-rotate(266deg) brightness(101%) contrast(101%);
}
.tabActiveExpanded > svg > path {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%)
    hue-rotate(207deg) brightness(107%) contrast(100%);
}
#dashboard-LeftPanel .p-panelmenu-header-content {
  @apply shadow-none border-none !bg-inherit;
}

#dashboard-LeftPanel .p-toggleable-content > .p-panelmenu-content {
  @apply !shadow-none !border-none !rounded-xl mt-3 dark:bg-[#1f1f1f];
}

#dashboard-LeftPanel .p-submenu-list > .p-menuitem:first-child .menu-item {
  margin-top: 0 !important;
}
</style>
