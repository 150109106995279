<script setup>
import { storeToRefs } from "pinia";
import { useLeftPanelStore } from "~/store/useLeftPanelStore.js";

const { isLeftPanelExpanded } = storeToRefs(useLeftPanelStore());
</script>

<template>
  <div>
    <meta name="viewport" content="" />

    <div class="fixed h-full bottom-0 w-full">
      <LeftPanel />
    </div>

    <div
      class="h-screen pr-3 py-3"
      :class="[isLeftPanelExpanded ? 'ml-64' : 'ml-20', { 'pl-3': true }]"
    >
      <div class="dark:bg-[#1f1f1f] bg-white rounded-3xl h-full p-4 relative">
        <div class="grid grid-cols-1 grid-auto-two-rows h-full">
          <div class="col-span-1">
            <NavbarDashboard />
          </div>

          <div class="col-span-1 overflow-y-hidden">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
