<script setup>
import { notifyError } from "~/utils/notifications";
import { storeToRefs } from "pinia";
import { useAuthStore } from "~/store/auth.js";
import { useDashboardNavStore } from "~/store/dashboardNav.js";
import { useLinksTableStore } from "~/store/useLinksTableStore.js";

const useAuth = useAuthStore();
const useDashboardNav = useDashboardNavStore();
const { dash_nav_search_input } = storeToRefs(useDashboardNav);
const { setSearchInput } = useLinksTableStore();
const { sendGetRequest } = useSendRequest();

const route = useRoute();

const apis = new APIS();
const { header } = storeToRefs(useAuth);

const search_result = ref();

async function search() {
  // console.log("==> search input: ", dash_nav_search_input.value);
  const request_uri = `${apis.BASE_URL + apis.SEARCH_LINK}`;
  const request_params = {
    q: dash_nav_search_input.value,
  };

  const request_headers = {
    headers: header.value,
    responseType: "json",
    params: request_params,
  };

  const response = await sendGetRequest(request_uri, request_headers);
  if (response.status === 200 || response.status === 404) {
    search_result.value = response.data?.data;
  } else {
    const message = response.data?.message || "An unknown error occurred.";
    notifyError({
      title: message,
    });
  }
}

function onSelect(event) {
  const { collection__id, is_it_link, smartlnk, type } = event.value;
  setSearchInput(smartlnk);

  const url = `/dashboard/collection/${collection__id}/${
    is_it_link ? "smartlnks" : "smartdomains"
  }#${smartlnk}`;

  // Navigate to the dashboard
  navigateTo(url);
}
</script>

<template>
  <span class="p-float-label mr-3 md:!block">
    <AutoComplete
      v-model="dash_nav_search_input"
      optionLabel="smartlnk"
      :suggestions="search_result"
      @complete="search"
      :delay="1000"
      :inputClass="'rounded-xl h-full bg-gray-100 dark:bg-[#181818] dark:text-white !border-none !shadow-sm nav-search !pl-9 w-80 lg:w-[20rem] md:w-[12rem] '"
      @item-select="onSelect($event)"
      :forceSelection="true"
      :autoOptionFocus="false"
      scrollHeight="500px"
      id="dashboard-nav-search"
      panelClass="w-80 lg:w-[30rem] md:w-[24rem]"
    >
      <template #option="slotProps">
        <CardDashboardNavSearchResult
          :smartlnk="slotProps.option.smartlnk"
          :destination_url="slotProps.option.destination_url"
          :is_it_link="slotProps.option.is_it_link"
          :type="slotProps.option.type"
          :collection_name="slotProps.option.collection__name"
        />
      </template>
      <template #empty>
        <div class="flex justify-center items-center h-40">
          <div>
            <img
              data-not-lazy
              src="~~/assets/images/no-search.png"
              class="h-10 mx-auto"
              alt="no-search-result"
            />
            <span class="font-semibold">No Result Found!! :(</span>
          </div>
        </div>
      </template>
    </AutoComplete>
    <i
      class="pi pi-search cursor-pointer absolute top-[15px] left-[15px] text-[#71717A]"
    />
    <label class="pl-8 md:text-sm" for="ac">Search Smartlnks...</label>
  </span>
</template>

<style lang="postcss" scoped>
#dashboard-nav-search_list > li {
  @apply p-0;
}
#dashboard-nav-search_list {
  @apply w-[400px];
}
</style>
