import {
  SvgOverview,
  SvgCollections,
  SvgCalendar,
  SvgActivity,
  SvgSettings,
  SvgArrow,
  SvgLocation,
  SvgComputer,
  SvgGlobe,
  SvgAutomation,
} from "#components";

const applyMarkRawToIcons = (items) => {
  return items.map((item) => ({
    ...item,
    icon: markRaw(item.icon),
    items: item.items ? applyMarkRawToIcons(item.items) : undefined,
  }));
};

export const useLeftPanel = () => {
  const rawMenuItems = [
    {
      key: "overview",
      label: " Overview",
      icon: SvgOverview,
      items: [
        {
          key: "overview-clicks",
          label: "Clicks",
          icon: SvgArrow,
          class: "bg-white",
          route: "/dashboard/overview/clicks",
        },
        {
          key: "overview-locations",
          label: "Locations",
          icon: SvgLocation,
          class: "bg-white",
          route: "/dashboard/overview/locations",
        },
        {
          key: "overview-traffic-sources",
          label: "Traffic Sources",
          icon: SvgComputer,
          class: "bg-white",
          route: "/dashboard/overview/traffic-sources",
        },
      ],
    },
    {
      key: "collection",
      label: "Collections",
      icon: SvgCollections,
      route: "/dashboard/collection",
    },
    {
      key: "automation",
      label: "Automation",
      icon: SvgAutomation,
      route: "/dashboard/automation",
    },
    {
      key: "globe",
      label: "Globe",
      icon: SvgGlobe,
      route: "/dashboard/globe",
    },
    {
      key: "calendar",
      label: "Calendar",
      icon: SvgCalendar,
      route: "/dashboard/calendar",
    },
    {
      key: "activities",
      label: "Activity",
      icon: SvgActivity,
      route: "/dashboard/activities",
    },
    {
      key: "settings",
      label: "Settings",
      icon: SvgSettings,
      route: "/dashboard/settings/my-details",
    },
  ];

  const menuItems = ref(applyMarkRawToIcons(rawMenuItems));

  return {
    menuItems,
  };
};
