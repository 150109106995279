<template>
	<svg
		class="w-6 h-6"
		width="25"
		height="25"
		fill="none"
		stroke="#9ca3af"
		stroke-linecap="round"
		stroke-linejoin="round"
		stroke-width="1.5"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M3 12h3l3-9 6 18 3-9h3"></path>
	</svg>
</template>