<script setup>
const props = defineProps({
  smartlnk: {
    type: String,
  },
  destination_url: {
    type: String,
  },
  collection_name: {
    type: String,
  },
  is_it_link: {
    type: Boolean,
  },
  type: {
    type: String,
    required: true,
  },
});

const getLinkTypeOption = () => {
  if (props.type === "PIXEL") {
    return "Manage SmartPixels";
  } else if (props.is_it_link) {
    return "Manage Smartlnks";
  } else {
    return "Manage SmartDomains";
  }
};
</script>
<template>
  <div class="shadow rounded-lg bg-white px-6 py-4 m-2 overflow-hidden">
    <span class="flex items-center flex-wrap font-semibold text-sm"
      ><CustomSocialIcons
        :url="destination_url"
        :dynamicClass="'mr-1'"
      />
      {{ smartlnk }}</span
    >
    <span class="text-xs flex flex-wrap overflow-auto remove-x-scroll">{{
      destination_url
    }}</span>
    <span class="flex flex-wrap text-xs font-medium mt-4">
      <span class="font-semibold mr-2"> Collection: </span>{{ collection_name }} > Links >
      {{ getLinkTypeOption() }} >
      <span class="break-all block"> {{ smartlnk }} </span>
    </span>
  </div>
</template>

<style scoped></style>
