<script setup>
import { storeToRefs } from "pinia";
import CountryFlag from "vue-country-flag-next";
import { useAuthStore } from "~/store/auth.js";
import { useLinksTableStore } from "~/store/useLinksTableStore.js";
import { useTimeAgo } from "@vueuse/core";

const { setSearchInput } = useLinksTableStore();

const realTimeAnalyticsOverlay = ref();
const toggleRealTimeAnalytics = (event) => {
  realTimeAnalyticsOverlay.value.toggle(event);
  if (!state.ws || state.ws.readyState === WebSocket.CLOSED) {
    connectWebSocket();
  }
};

const analyticsData = ref([]);
const analyticsDataSet = new Set();
const loading = ref(true);

const { token } = storeToRefs(useAuthStore());

// ============================= Web Socket =============================
const state = reactive({
  links: [],
  ws: null,
});

const connectWebSocket = () => {
  const wsPath = getWebSocketPath();
  state.ws = new WebSocket(wsPath);

  state.ws.onopen = () => {
    console.log("WebSocket connection established.");
    loading.value = false;
  };

  state.ws.onmessage = handleWebSocketMessage;

  state.ws.onerror = handleWebSocketError;

  state.ws.onclose = handleWebSocketClose;
};

const getWebSocketPath = () => {
  const wsProtocol = window.location.protocol === "https:" ? "wss://" : "ws://";
  return `${wsProtocol}api.smartlnks.co/ws/analytics/realtime?token=${token.value}`;
};

const handleWebSocketMessage = (event) => {
  const data = JSON.parse(event.data);
  const stringifiedData = JSON.stringify(data);

  if (!analyticsDataSet.has(stringifiedData)) {
    analyticsDataSet.add(stringifiedData);
    analyticsData.value.unshift(data);
  }
};

const handleWebSocketError = (error) => {
  console.error("WebSocket error:", error);
};

const handleWebSocketClose = () => {
  console.log("WebSocket connection closed.");

  // Try to reconnect after 5 seconds
  setTimeout(connectWebSocket, 5000);
};

const getRegion = (data) => {
  const { region, state } = data;

  if (region.toLowerCase() === "not found") {
    return "Unknown Location";
  }

  if (state === "not found" || state === "Unknown State") {
    return `Somewhere from ${region}`;
  }

  return `${state}, ${region}`;
};

const searchSelectedLink = (data) => {
  const { collection_id, is_it_link, smartlnk } = data;
  const url = `/dashboard/collection/${collection_id}/${
    is_it_link ? "smartlnks" : "smartdomains"
  }#${smartlnk}`;

  setSearchInput(smartlnk);

  // Navigate to the dashboard
  navigateTo(url);
};
</script>

<template>
  <button
    @click="toggleRealTimeAnalytics"
    type="button"
    class="relative md:inline-flex lg:inline-flex items-center border px-4 p-3 text-sm text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 font-medium text-center rounded-lg focus:ring-0 focus:outline-none mr-3 select-none"
  >
    Real Time Clicks<span class="live-icon"></span>
  </button>

  <OverlayPanel
    class="!shadow-5xl border rounded-xl"
    ref="realTimeAnalyticsOverlay"
    id="notification-overlay-panel"
  >
    <!-- loader -->
    <div v-if="loading" class="loading-overlay">
      <br />
      <LoadersRingLoader />
    </div>
    <div class="w-96" :class="{ 'h-36': loading }">
      <h1 class="font-bold mb-4 flex items-center">
        Real Time Clicks
        <span class="ml-1.5 !rounded-md !text-sm bg-[#22C55E] text-white px-2 py-0.5">
          <div class="flex items-center">Live <span class="live-icon"></span></div>
        </span>
      </h1>
      <div class="w-full max-h-96 overflow-scroll remove-scroll">
        <div
          v-if="analyticsData.length"
          v-for="data in analyticsData"
          :key="data.id"
          class="mb-5"
        >
          <div class="flex justify-between">
            <!-- smartlnk -->
            <div class="">
              <a
                :href="data.smartlnk"
                target="_blank"
                class="text-sm font-medium text-primary break-all w-[70%]"
              >
                {{ data.smartlnk }}
              </a>
              <i
                class="pi pi-search ml-2 !text-xs cursor-pointer"
                @click="searchSelectedLink(data)"
                v-tooltip="{
                  value: `check this ${data.is_it_link ? 'SmartLnk' : 'SmartDomain'}`,
                  class: '!text-xs round-tooltip',
                }"
              ></i>
            </div>
            <!-- time ago -->
            <span class="text-xs">
              {{ useTimeAgo(data.timestamp).value }}
            </span>
          </div>
          <div class="flex flex-wrap justify-between">
            <span class="text-xs flex items-center"
              ><country-flag
                :country="data.country_code"
                size="small"
                :rounded="false"
                class="rounded-lg"
              />
              {{ getRegion(data) }}</span
            >
            <span class="text-xs flex items-center">
              {{ data.platform }}
            </span>
            <span class="text-xs flex items-center">
              <i class="pi pi-globe mr-1.5 !text-xs"></i> {{ data.browser }}
            </span>
            <span class="text-xs flex items-center ml-1 break-all">
              <CustomSocialIcons
                :url="data.referral"
                :dynamicClass="'mr-1.5 !h-3.5 !w-3.5'"
              />{{ data.referral }}
            </span>
          </div>
        </div>
        <div v-else-if="!loading && analyticsData.length === 0">
          <span
            >No realtime clicks at the moment, promote your link and see the clicks roll
            in!</span
          >
        </div>
      </div>
    </div>
  </OverlayPanel>
</template>

<style lang="postcss">
#notification-overlay-panel > .p-overlaypanel-content {
  @apply rounded-xl shadow-2xl;
}
</style>
